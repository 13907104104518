<template>
  <form novalidate="true" @submit.prevent="submit">
    <div class="columns is-multiline">
      <b-field
        class="column is-full-mobile is-half"
        label="Prénom*"
        :message="fields.firstname.error"
        :type="fields.firstname.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.firstname.value" type="text" />
      </b-field>
      <b-field
        class="column is-full-mobile is-half"
        label="Nom*"
        :message="fields.lastname.error"
        :type="fields.lastname.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.lastname.value" type="text" />
      </b-field>
    </div>
    <div class="columns is-multiline">
      <b-field
        class="column is-full-mobile is-half"
        label="Email*"
        :message="fields.email.error"
        :type="fields.email.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.email.value" type="email" />
      </b-field>
      <b-field
        class="column is-full-mobile is-half"
        label="Mot de passe*"
        :message="fields.password.error"
        :type="fields.password.error !== null ? 'is-danger' : ''"
      >
        <b-input v-model="fields.password.value" type="password" password-reveal expanded />
        <p class="control">
          <b-button type="is-primary" icon-right="sync" @click="initPassword" />
        </p>
      </b-field>
    </div>
    <hr />
    <b-field>
      <b-button native-type="submit" type="is-primary" :loading="loading" :disabled="disabled">
        Modifier
      </b-button>
    </b-field>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmail } from '@helpers/isEmail'
import { isPassword } from '@helpers/isPassword'

export default {
  name: 'AddAdministratorForm',

  data() {
    return {
      loading: false,
      fields: {
        firstname: {
          value: '',
          error: null,
        },
        lastname: {
          value: '',
          error: null,
        },
        email: {
          value: '',
          error: null,
        },
        password: {
          value: '',
          error: null,
        },
      },
    }
  },

  created() {
    this.initPassword()
  },

  watch: {
    'fields.firstname.value'(firstname) {
      this.fields.firstname.error = firstname.length > 0 ? null : 'Prénom requis.'
    },

    'fields.lastname.value'(lastname) {
      this.fields.lastname.error = lastname.length ? null : 'Nom requis.'
    },

    'fields.email.value'(email) {
      this.fields.email.error = email.length
        ? isEmail(email)
          ? null
          : 'Email incorrect'
        : 'Email requis.'
    },

    'fields.password.value'(password) {
      this.fields.password.error = password.length
        ? isPassword(password)
          ? null
          : '8 caratères minimum, avec au moins une lettre, un chiffre et un caractère spécial.'
        : 'Mot de passe requis.'
    },
  },

  computed: {
    disabled() {
      return this.loading || Object.values(this.fields).some((field) => field.error !== null)
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async submit() {
      this.loading = true

      try {
        await this.$services.administratorService.create({
          firstname: this.fields.firstname.value,
          lastname: this.fields.lastname.value,
          email: this.fields.email.value,
          password: this.fields.password.value.trim(),
        })

        this.$emit('submit')

        this.addToastMessage({
          text: 'Les modifications ont bien été enregistré.',
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },

    initPassword() {
      this.fields.password.value = this.generatePassword()
    },

    generatePassword() {
      const specialChars = '@$!%*?&'
      return (
        Math.random().toString(36).substring(2, 10) +
        specialChars[Math.floor(Math.random() * specialChars.length)]
      )
    },
  },
}
</script>
