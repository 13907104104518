<template>
  <page-layout title="Administrateurs">
    <template v-slot:breadcrumb>
      <ul>
        <li><a href="#">Dashboard</a></li>
        <li class="is-active">
          <a href="#"> Administrateurs </a>
        </li>
      </ul>
    </template>
    <div class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button
            tag="router-link"
            type="is-success"
            :to="{ name: 'administrator.add' }"
            icon-left="plus"
            label="Nouveau"
          />
        </p>
      </div>
    </div>
    <div class="box is-relative">
      <b-loading :is-full-page="false" :active="loading" />
      <table class="table is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody v-show="!loading && administrators.length > 0">
          <tr v-for="administrator in administrators" :key="administrator.id">
            <td>{{ administrator.firstname }} {{ administrator.lastname }}</td>
            <td>{{ administrator.email }}</td>
            <td>
              <div class="buttons are-small is-right">
                <b-button
                  type="is-primary"
                  tag="router-link"
                  :to="{
                    name: 'administrator.edit',
                    params: { id: administrator.id },
                  }"
                >
                  <b-icon icon="edit" />
                </b-button>
                <b-button
                  type="is-danger"
                  icon-right="trash"
                  @click="selectedAdministrator = administrator"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="!loading && errorMessage" class="has-text-grey has-text-centered">
        {{ errorMessage }}
      </div>
    </div>
    <o-modal
      :title="modalTitle"
      :isOpen="!!selectedAdministrator"
      @closeModal="selectedAdministrator = null"
      @confirmModalAction="deleteAdministrator"
    >
      <template> La suppression d'un administrateur est <strong>définitive</strong>. </template>
    </o-modal>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import OModal from '@components/Modal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ListAdministratorPage',

  components: {
    OModal,
    [PageLayout.name]: PageLayout,
  },

  metaInfo() {
    return {
      title: 'Administrateurs',
    }
  },

  data() {
    return {
      loading: true,
      errorMessage: null,
      administrators: [],
      selectedAdministrator: null,
    }
  },

  async mounted() {
    await this.fetch()
  },

  computed: {
    modalTitle() {
      return this.selectedAdministrator
        ? `Supprimer ${this.selectedAdministrator.firstname} ${this.selectedAdministrator.lastname}`
        : ''
    },
  },

  methods: {
    ...mapActions('ui', ['addToastMessage']),

    async fetch() {
      this.errorMessage = null
      this.loading = true

      try {
        this.administrators = await this.$services.administratorService.getAll()
      } catch (err) {
        this.errorMessage = 'Une erreur interne est survenue.'
        console.error({ err })
      }

      if (!this.errorMessage && this.administrators.length === 0) {
        this.errorMessage = 'Aucun administrateur'
      }

      this.loading = false
    },

    async deleteAdministrator() {
      try {
        const deletedAdministrator = await this.$services.administratorService.delete(
          this.selectedAdministrator.id,
        )

        this.administrators = this.administrators.filter(
          (admin) => admin.id !== deletedAdministrator.id,
        )

        this.addToastMessage({
          text: `L'administrateur "${deletedAdministrator.firstname} ${deletedAdministrator.lastname}" a été supprimé.`,
          type: 'is-success',
        })
      } catch (err) {
        this.addToastMessage({
          text: err.message ? err.message : 'Une erreur interne est survenue.',
          type: 'is-danger',
        })
      }
    },
  },
}
</script>
