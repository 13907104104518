<template>
  <page-layout title="Ajouter un administrateur">
    <template v-slot:breadcrumb>
      <ul>
        <li><a href="#">Dashboard</a></li>
        <li>
          <router-link :to="{ name: 'administrator.list' }"> Administrateurs </router-link>
        </li>
        <li class="is-active">
          <a href="#"> Ajouter un administrateur </a>
        </li>
      </ul>
    </template>
    <div class="card">
      <div class="card-content">
        <add-administrator-form @submit="$router.push({ name: 'administrator.list' })" />
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from '@components/PageLayout.vue'
import AddAdministratorForm from './AddAdministratorForm.vue'

export default {
  name: 'AddAdministratorPage',

  components: {
    PageLayout,
    AddAdministratorForm,
  },
}
</script>
